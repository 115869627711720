<template>
  <div class="discount-form-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="users" :title="pageTitle" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="row">
              <div class="col-3">
                <app-input
                  label="Código"
                  v-model="discount.code"
                  :errors="errors.code"
                  is-required
                  type="text"
                  :onkeyup="formatCode()"
                  :onkeydown="formatCode()"
                  onkeypress="return event.charCode !== 32"
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Valor"
                  v-model="discount.value"
                  :errors="errors.value"
                  is-required
                  type="text"
                  :mask="MASKS.numberBr"
                  :onkeyup="formatValue(discount.value)"
                ></app-input>
              </div>
              <div class="col-3">
                <b-field class="required" label="Tipo do voucher" required>
                  <b-select
                    v-model="discount.discount_type"
                    placeholder="Selecionar"
                    name="discount_type"
                    expanded
                  >
                    <option
                      v-for="discount_type in discount_types"
                      :key="discount_type.id"
                      :value="discount_type.id"
                    >
                      {{ discount_type.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-3">
                <b-field label="Data de validade">
                  <by-datepicker
                    trap-focus
                    icon="calendar"
                    :locale="'pt-BR'"
                    v-model="discount.due_date"
                    :years-range="[-100, 100]"
                    placeholder="Selecionar data..."
                    position="is-bottom-left"
                  >
                  </by-datepicker>
                </b-field>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  class="button-rounded remove-focus btn-success border-0 px-3"
                  @click="onSubmit"
                  :loading="isSaving"
                  :disabled="isLoading || isSaving"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DiscountsService from '@/services/discounts.service';

import flattenDeep from 'lodash/flattenDeep';
import MASKS from '@/constants/masks.constant';
import moment from 'moment';

export default {
  data: () => ({
    MASKS: MASKS,
    discountId: null,
    isLoading: false,
    isSaving: false,
    discount: {
      name: '',
      user: { permissions: [] },
    },
    errors: {},
    permissions: [],
    userPermissions: [],
    discount_types: [
      {
        id: 1,
        name: 'Dinheiro',
      },
      {
        id: 2,
        name: 'Percentual',
      },
    ],
  }),
  computed: {
    pageTitle() {
      return `${this.discountId ? 'Editar' : 'Cadastrar'} Voucher`;
    },
  },
  methods: {
    unmask(value) {
      if (!value) return value;
      return value.replaceAll('.', '').replace(',', '.');
    },
    formatValue(value) {
      if (
        this.discount.discount_type == 2 &&
        parseFloat(this.unmask(value)) > 100.0
      ) {
        this.discount.value = '';
        this.$forceUpdate();
      }
    },
    formatCode() {
      if (typeof this.discount.code != 'undefined') {
        this.discount.code = this.discount.code
          .replaceAll(' ', '')
          .replaceAll('  ', '')
          .toUpperCase();
      }
    },
    onSubmit() {
      this.isSaving = true;
      this.errors = {};
      const data = {
        code: this.discount.code,
        value: this.discount.value,
        discount_type: this.discount.discount_type,
        due_date: this.discount.due_date
          ? moment(this.discount.due_date).format('YYYY-MM-DD')
          : '',
      };

      const promise = this.discountId
        ? DiscountsService.update(this.discountId, data)
        : DiscountsService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Voucher salvo com sucesso.');
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          this.isSaving = false;
          const { status, data } = response;

          if (status === 422) this.errors = flattenDeep(data);
          else
            this.$buefy.snackbar.open(
              data.message || 'Voucher salvo com sucesso.'
            );
        });
    },
    loadDiscount(id) {
      this.isLoading = true;
      if (typeof id == 'undefined') {
        this.isLoading = false;
      } else {
        DiscountsService.getId(id)
          .then(({ data }) => {
            this.loadPermissions(this.discount.user.permissions);
            this.discount = {
              ...data,
              birthday: new Date(data.birthday),
            };
          })
          .catch(() => this.$buefy.snackbar.open('Erro ao carregar voucher.'))
          .finally(() => (this.isLoading = false));
      }
    },
  },
  mounted() {
    this.discountId = Number(this.$route.params.id);
    this.discountId && this.loadDiscount(this.discountId);
  },
};
</script>
